<template>
  <main class="participant">
    <div
      class="participant__status-icon"
      :class="`participant__status-icon--${statusIconClassPostfix}`"
    ></div>
    <div
      class="participant__name ellipsized"
      :class="{ 'participant__name--wide': !isOneParticipant }"
    >
      {{ participant.name }}
    </div>
    <div class="participant__points-container flex">
      <div class="participant__total-points">
        {{ getTotalPointsCountTranslation(participant.totalPoints) }}
      </div>
      <div class="participant__current-points">
        {{ getPointsCountTranslation(participant.currentPoints) }}
      </div>
    </div>
    <button
      class="participant__redeem-button"
      :class="[isRedeemAvailable ? 'rounded-button-white' : 'rounded-button-gray']"
      @click="onRedeemClick"
    >
      {{ $t('dashboard.button.redeem') }}
    </button>
    <div v-if="shouldShowMenu" ref="dropdown" class="participant-dropdown">
      <div
        class="vertical-dots dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
        @click="toggleDropdown"
      ></div>
      <div
        class="participant-dropdown__options-dropdown dropdown-menu dropdown-menu-right main-dropdown"
        :class="{ 'participant-dropdown__options-dropdown--opened': isDropdownOpened }"
      >
        <div class="main-dropdown__option option__redeem" @click="onRedeemClick">
          <a class="main-dropdown__option-link flex--align-center">
            <div
              class="main-dropdown__option option__redeem-text"
              :class="`option__redeem-text--${isRedeemAvailable ? 'orange' : 'black'}`"
            >
              {{ $t('dashboard.button.redeem') }}
            </div>
          </a>
        </div>
        <div v-if="!isOneParticipant" class="main-dropdown__option" @click="onDeleteOptionClick">
          <a class="main-dropdown__option-link flex--align-center">
            <span class="far fa-trash-alt dropdown-icon mr15"></span>
            <div class="main-dropdown__option__text">
              {{ $t('dashboard.action.deleteParticipant') }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import RoutingInfo from '@/modules/dashboard/components/common/RoutingInfo';

import { legacyDropdownMixin } from '@/modules/dashboard/mixins/dropdown/legacyDropdownMixin';

import {
  handleRoutingInfo,
  isMobileDevice,
  getPointsCountTranslation
} from '@/modules/dashboard/utils';

import { deleteLoyaltyProgramParticipant } from '@/modules/dashboard/api';

import rootTypes from '@/store/types';

const DEFAULT_STATUS_COLOR = 'GREY';

export default {
  name: 'ParticipantListItem',
  mixins: [legacyDropdownMixin],
  props: {
    participant: {
      type: Object,
      required: true
    },
    isOneParticipant: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    }),
    shouldShowMenu() {
      return !this.isOneParticipant || window.matchMedia('(max-width: 450px)').matches;
    },
    participantStatusColor() {
      return this.participant.statusColor || DEFAULT_STATUS_COLOR;
    },
    isRedeemAvailable() {
      return this.participantStatusColor !== DEFAULT_STATUS_COLOR;
    },
    statusIconClassPostfix() {
      return this.participantStatusColor.toLowerCase();
    }
  },
  methods: {
    onRedeemClick() {
      handleRoutingInfo(this.getRedeemRoutingInfo(), this.$router, this.$modal);
    },
    getRedeemRoutingInfo() {
      const params = isMobileDevice()
        ? { participantId: this.participant.id }
        : { participant: this.participant };

      return new RoutingInfo('loyalty-program-redeem-modal', 'LoyaltyProgramRedeem', params);
    },
    async onDeleteOptionClick() {
      const { id, currentPoints } = this.participant;

      if (currentPoints === 0) {
        await deleteLoyaltyProgramParticipant(this.userId, {
          fromId: id,
          toId: null
        });

        this.$modal.show('participant-deleted-modal');

        return;
      }

      this.$modal.show('delete-participant-modal', {
        fromParticipantId: id
      });
    },
    getPointsCountTranslation(numberOfPoints) {
      return getPointsCountTranslation(this.$t, numberOfPoints);
    },
    getTotalPointsCountTranslation(numberOfPoints) {
      return getPointsCountTranslation(this.$t, numberOfPoints, true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons';
@import '../../../../assets/css/common/dropdowns';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.participant {
  width: 100%;
  height: 80px;

  display: flex;
  align-items: center;

  padding: 15px;

  border: 1px solid $border-light-grey-color;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &__status-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 50%;

    &--grey {
      background-color: $grey-color;
    }

    &--bronze {
      background-color: $bright-orange-color;
    }

    &--gold {
      background-color: $festival-color;
    }
  }

  &__name {
    width: 180px;
    font-size: 15px;
    line-height: 19px;
    color: $main-title-color;
    font-weight: bold;

    &--wide {
      width: 210px;
    }
  }

  &__total-points {
    width: 185px;
    font-size: 13px;
    line-height: 15px;
    color: $grey-color;
  }

  &__current-points {
    width: 125px;
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
    color: $text-color;
  }

  &__status-icon,
  &__name,
  &__total-points,
  &__current-points,
  &__redeem-button {
    margin-right: 15px;
  }
}

.main-dropdown {
  padding: 10px 0;

  &__option {
    &-link {
      padding: 10px 20px;
    }

    &__text {
      font-size: 15px;
      line-height: 17px;
      color: $text-color;
    }

    &:hover {
      background-color: $dim-background;
    }
  }
}

.participant-dropdown {
  position: relative;

  &__options-dropdown {
    &--opened {
      display: block;
    }
  }
}

.total-point {
  &__points {
    display: inline;
  }
}

.option {
  &__redeem {
    display: none;
  }

  &__redeem-text {
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;

    &--black {
      color: $text-color;
    }

    &--orange {
      color: $orange-color;
    }
  }
}

@media (max-width: 767px) {
  .participant {
    height: 50px;

    padding: 15px 0;

    border: 0;
    border-radius: 0;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    &__status-icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    &__name {
      width: 45%;
      font-size: 12px;
      line-height: 14px;

      margin-right: 30px;
    }

    &__total-points {
      width: 150px;
    }
  }

  .main-dropdown {
    padding: 10px 0;

    &__option {
      &__text {
        font-size: 13px;
        line-height: 15px;
      }
    }
  }
}

@media (max-width: 599px) {
  .participant {
    height: 50px;

    padding: 15px 0;

    border: 0;
    border-radius: 0;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    &__status-icon {
      width: 16px;
      height: 16px;

      flex-shrink: 0;

      margin-right: 10px;
    }

    &__name {
      width: 45%;
      font-size: 12px;
      line-height: 14px;

      margin-right: 30px;
    }

    &__points-container {
      width: 25%;
      flex-direction: column;
      margin-right: 15px;
    }

    &__total-points {
      width: 100%;
      order: 2;
      font-size: 10px;
      line-height: 12px;
      margin-right: 0;
      text-align: center;
    }

    &__current-points {
      width: 100%;
      order: 1;
      font-size: 12px;
      line-height: 14px;
      margin-right: 0;
      text-align: center;
    }
  }

  .total-point {
    &__points {
      display: none;
    }
  }
}

@media (max-width: 450px) {
  .participant {
    &__name {
      margin-right: auto;
    }

    &__redeem-button {
      display: none;
    }
  }

  .option {
    &__redeem {
      display: block;
    }
  }
}
</style>
