<template>
  <modal
    name="contact-information-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="800"
    :height="700"
    @before-open="onBeforeOpen"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <modal-header-with-previous
          class="modal-header-with-previous"
          current-modal-name="contact-information-modal"
          previous-modal-name="loyalty-program-redeem-modal"
          :previous-modal-params="{ participantId }"
        >
          <span slot="header-title">
            {{ $t('dashboard.label.contactInformation') }}
          </span>
        </modal-header-with-previous>
        <contact-information
          :participant-id="participantId"
          :redeem-type="redeemType"
          @update-paticipant-list="updatePaticipantsList"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import ContactInformation from '@/modules/dashboard/components/loyalty-program/ContactInformation';

export default {
  name: 'ContactInformationModal',
  components: { ModalHeaderWithPrevious, ContactInformation },
  data() {
    return {
      participantId: null,
      redeemType: null
    };
  },
  methods: {
    onBeforeOpen({ params: { participantId, redeemType } }) {
      this.participantId = participantId;
      this.redeemType = redeemType;
    },
    updatePaticipantsList() {
      this.$emit('update-paticipant-list');
    }
  }
};
</script>
