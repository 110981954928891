<template>
  <main class="participant-list">
    <participant-list-item
      v-for="participant in participants"
      :key="participant.id"
      :participant="participant"
      :is-one-participant="isOneParticipant"
    />
  </main>
</template>

<script>
import ParticipantListItem from '@/modules/dashboard/components/loyalty-program/ParticipantListItem';

export default {
  name: 'ParticipantsList',
  components: { ParticipantListItem },
  props: {
    participants: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isOneParticipant() {
      return this.participants.length < 2;
    }
  }
};
</script>

<style lang="scss" scoped>
.participant-list {
  width: 740px;

  margin: 40px auto 0;
}

@media (max-width: 767px) {
  .participant-list {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 100px;
  }
}
</style>
