<template>
  <modal
    name="loyalty-program-redeem-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    :width="800"
    :height="720"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <modal-header current-modal-name="loyalty-program-redeem-modal">
        <span slot="header-title">{{ $t('dashboard.button.redeem') }}</span>
      </modal-header>
      <redeem :participant-id="participant.id" :participant="participant" />
    </div>
  </modal>
</template>

<script>
import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';
import Redeem from '@/modules/dashboard/components/loyalty-program/Redeem';

export default {
  name: 'RedeemModal',
  components: { ModalHeader, Redeem },
  data() {
    return {
      participant: {}
    };
  },
  methods: {
    beforeOpen({ params: { participant } }) {
      this.participant = participant;
    }
  }
};
</script>
