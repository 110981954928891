<template>
  <modal
    name="delete-participant-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="300"
    height="auto"
    @before-open="onModalBeforeOpen"
    @before-close="onModalBeforeClose"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <div class="modal__title mb30">{{ $t('dashboard.label.deleteParticipant') }}</div>
        <div class="modal__select-container mb20">
          <div class="select__label mb10">{{ $t('dashboard.label.transferPointsTo') }}</div>
          <q-select
            label="label"
            :allow-empty="false"
            :value="selectedParticipant"
            :options="participants"
            placeholder=""
            class="modal__select custom--with-border"
            :class="{ 'custom--error': participantFieldError }"
            track-by="label"
            @input="onParticipantInput"
          />
        </div>
        <section class="modal__button-container flex--center--center">
          <button class="cancel-button mr20" @click="closeModal">
            {{ $t('dashboard.action.cancel') }}
          </button>
          <button class="modal__delete-button rounded-button-white" @click="deleteParticipant">
            {{ $t('dashboard.action.delete') }}
          </button>
        </section>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';

import QSelect from '@/modules/dashboard/components/common/Select';

import {
  getLoyaltyProgramParticipants,
  deleteLoyaltyProgramParticipant
} from '@/modules/dashboard/api';

import rootTypes from '@/store/types';

export default {
  name: 'DeleteParticipantModal',
  components: { QSelect },
  data() {
    return {
      fromParticipantId: null,
      selectedParticipant: null,
      participantsList: [],
      participantFieldError: false,
      participantsDropdownRef: null
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    }),
    participants() {
      return this.participantsList
        .filter(({ id }) => id !== this.fromParticipantId)
        .map(({ name, id }) => ({
          label: name,
          value: id
        }));
    }
  },
  methods: {
    async onModalBeforeOpen({ params: { fromParticipantId } }) {
      this.fromParticipantId = fromParticipantId;

      if (!this.userId) {
        return;
      }

      const { data } = await getLoyaltyProgramParticipants(this.userId);
      this.participantsList = data || [];

      const participantsDropdownRef = document.body.querySelector(
        '.modal__select .multiselect__content-wrapper'
      );

      this.participantDropdownScrollbar = new PerfectScrollbar(participantsDropdownRef, {
        suppressScrollX: true,
        wheelPropagation: false
      });
    },
    onModalBeforeClose() {
      this.selectedParticipant = null;
      this.participantFieldError = false;
      this.participantsDropdownRef = null;
    },
    onParticipantInput(participant) {
      if (this.participantFieldError) {
        this.participantFieldError = false;
      }

      this.selectedParticipant = participant;
    },
    async deleteParticipant() {
      if (!this.selectedParticipant) {
        this.participantFieldError = true;

        return;
      }

      const { value: id } = this.selectedParticipant;

      if (!id || !this.fromParticipantId) {
        return;
      }

      await deleteLoyaltyProgramParticipant(this.userId, {
        fromId: this.fromParticipantId,
        toId: id
      });

      this.$modal.show('participant-deleted-modal', {
        deletedWithPointsTransfer: true
      });

      this.closeModal();
    },
    closeModal() {
      this.$modal.hide('delete-participant-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/errors';
@import '../../../../assets/css/common/icons';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.modal {
  &-window-content {
    padding: 30px 20px;
  }

  &__title {
    text-align: center;
    font-size: 15px;
    line-height: 17px;
    color: $grey-color;
  }

  &__select {
    width: 260px;
  }

  &__confirm-container {
    padding: 20px 15px;
    background: $beige;
    border-radius: 8px;
  }

  &__delete-button {
    height: 36px;
  }
}

.select {
  &__label {
    font-size: 13px;
    font-weight: 300;
    color: $text-color;
  }
}

.info {
  &__icon {
    color: $pale-orange-color;
  }

  &__text {
    font-size: 11px;
    font-weight: 300;
    line-height: 13px;
    color: $text-color;
  }
}

.phone {
  &__label {
    font-size: 13px;
    font-weight: 300;
    color: $text-color;
  }

  &__input {
    /deep/ & .phone-number {
      &__container {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .modal {
    &__title {
      font-size: 13px;
      line-height: 15px;
    }

    &__delete-button {
      height: 32px;
    }
  }

  .select {
    &__label {
      font-size: 12px;
    }
  }

  .info {
    &__text {
      font-size: 11px;
      line-height: 13px;
    }
  }

  .phone {
    &__label {
      font-size: 12px;
    }
  }
}
</style>
