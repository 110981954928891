<template>
  <modal
    name="add-participant-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="300"
    height="auto"
    @before-close="resetModalData"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <main>
          <div class="modal__title">
            {{ $t('dashboard.label.addLoyaltyProgramParticipant') }}
          </div>
          <div class="modal__input-container">
            <label for="participantNameInput" class="input__label">
              {{ $t('dashboard.label.participantName') }}
              <span class="input__compulsory-icon">*</span>
            </label>
            <input
              id="participantNameInput"
              v-model="name"
              type="text"
              class="modal__input rounded-input input-focus-within"
              :class="{ 'input--error': hasNameIsEmptyError || hasNameInUseError }"
              @input="onNameInput"
            />
            <field-error
              v-if="hasNameInUseError"
              :error-message="$t('dashboard.warning.addParticipant')"
            />
          </div>
          <div class="modal__buttons-container flex--center--center">
            <div class="cancel-button pointer mr20" @click="closeModal">
              {{ $t('dashboard.action.cancel') }}
            </div>
            <button class="modal__add-button rounded-button-white" @click="addParticipant">
              {{ $t('dashboard.button.add') }}
            </button>
          </div>
        </main>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';

import FieldError from '@/modules/dashboard/components/common/FieldError';

import { addLoyaltyProgramParticipant } from '@/modules/dashboard/api';

import { ERROR_MESSAGES } from '@/constants';
import rootTypes from '@/store/types';

export default {
  name: 'AddParticipantModal',
  components: { FieldError },
  data() {
    return {
      name: '',
      hasNameIsEmptyError: false,
      hasNameInUseError: false
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    })
  },
  methods: {
    onNameInput() {
      if (this.hasNameIsEmptyError) {
        this.hasNameIsEmptyError = false;
      }

      if (this.hasNameInUseError) {
        this.hasNameInUseError = false;
      }
    },
    closeModal() {
      this.$modal.hide('add-participant-modal');
    },
    async addParticipant() {
      if (this.name === '') {
        this.hasNameIsEmptyError = true;

        return;
      }

      try {
        const {
          data: { message }
        } = await addLoyaltyProgramParticipant(this.userId, { name: this.name });

        if (message && message === ERROR_MESSAGES.BAD_REQUEST) {
          this.hasNameInUseError = true;

          return;
        }
      } catch (error) {
        this.hasNameInUseError = true;

        return;
      }

      this.$emit('add-paticipant');
      this.closeModal();
    },
    resetModalData() {
      this.name = '';
      this.hasNameIsEmptyError = false;
      this.hasNameInUseError = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/inputs';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.modal {
  &-window-content {
    padding: 30px 20px;
  }

  &__title {
    margin-bottom: 30px;
    text-align: center;
    font-size: 15px;
    line-height: 17px;
    color: $grey-color;
  }

  &__input-container {
    margin-bottom: 15px;
  }

  &__input {
    width: 260px;
  }

  &__add-button {
    height: 36px;
  }
}

.input {
  &--error {
    border-color: $error-color;
  }

  &__label {
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 19px;
    font-weight: 300;
    color: $text-color;
  }

  &__compulsory-icon {
    color: $error-color;
  }
}

@media (max-width: 767px) {
  .modal {
    &__title {
      font-size: 13px;
      line-height: 15px;
    }

    &__add-button {
      height: 32px;
    }
  }

  .input {
    &__label {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .cancel-button {
    font-size: 13px;
    line-height: 15px;
  }
}
</style>
