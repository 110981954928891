<template>
  <template-with-header>
    <main class="loyalty-program__container">
      <section class="loyalty-program__breadcrumbs">
        <span class="breadcrumbs__link">
          <router-link :to="{ name: 'Dashboard' }" :title="$t('dashboard.action.home')">
            {{ $t('dashboard.action.home') | upperCase }}
          </router-link>
        </span>
        /
        <span class="breadcrumbs__current no-search-field">
          {{ $t('dashboard.label.loyaltyProgram') }}
        </span>
        <span
          class="breadcrumbs__info-icon custom-icon icon-info"
          @click="openMobileInfoModal"
        ></span>
      </section>
      <section class="loyalty-program__info">
        <div class="info__image custom-icon icon-loyalty-program cursor-default"></div>
        <div class="info__text">
          <span class="bold mb18">{{ $t('dashboard.label.introductionWelcome') }}</span>
          <span class="pre-wrap">{{ $t('dashboard.label.introduction') }}</span>
        </div>
      </section>
      <section class="loyalty-program__add-participent-container">
        <button
          class="loyalty-program__add-participant-button rounded-button-orange flex--align-center"
          @click="openAddParticipantModal"
        >
          <div class="loyalty-program__plus-icon custom-icon icon-plus-active mr10"></div>
          {{ $t('dashboard.button.addParticipant') }}
        </button>
      </section>
      <mobile-fixed-footer class="loyalty-program__mobile-footer">
        <button
          slot="footer"
          class="loyalty-program__add-participant-button rounded-button-orange flex--align-center"
          @click="openAddParticipantModal"
        >
          <div class="custom-icon icon-plus-active  mr10"></div>
          {{ $t('dashboard.button.addParticipant') }}
        </button>
      </mobile-fixed-footer>

      <participants-list :participants="participants" />
    </main>

    <loyalty-program-info-modal />
    <add-participant-modal @add-paticipant="loadParticipantsList" />
    <redeem-modal />
    <contact-information-modal @update-paticipant-list="loadParticipantsList" />
    <delete-participant-modal />
    <participant-deleted-modal @update-paticipant-list="loadParticipantsList" />
    <sms-sent-modal />
  </template-with-header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TemplateWithHeader from '@/modules/dashboard/components/templates/TemplateWithHeader';
import MobileFixedFooter from '@/modules/dashboard/components/dashboard/common/MobileFixedFooter';
import LoyaltyProgramInfoModal from '@/modules/dashboard/components/loyalty-program/LoyaltyProgramInfoModal';
import ParticipantsList from '@/modules/dashboard/components/loyalty-program/ParticipantsList';
import AddParticipantModal from '@/modules/dashboard/components/loyalty-program/AddParticipantModal';
import RedeemModal from '@/modules/dashboard/components/loyalty-program/RedeemModal';
import DeleteParticipantModal from '@/modules/dashboard/components/loyalty-program/DeleteParticipantModal';
import ParticipantDeletedModal from '@/modules/dashboard/components/loyalty-program/ParticipantDeletedModal';
import ContactInformationModal from '@/modules/dashboard/components/loyalty-program/ContactInformationModal';
import SmsSentModal from '@/modules/dashboard/components/loyalty-program/SmsSentModal';

import { getLoyaltyProgramParticipants } from '@/modules/dashboard/api';

import rootTypes from '@/store/types';

export default {
  name: 'LoyaltyProgram',
  components: {
    TemplateWithHeader,
    MobileFixedFooter,
    LoyaltyProgramInfoModal,
    ParticipantsList,
    AddParticipantModal,
    RedeemModal,
    DeleteParticipantModal,
    ParticipantDeletedModal,
    ContactInformationModal,
    SmsSentModal
  },
  data() {
    return {
      participants: []
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    })
  },
  async mounted() {
    await this.loadParticipantsList();
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING
    }),
    openMobileInfoModal() {
      this.$modal.show('loyalty-program-info-modal');
    },
    openAddParticipantModal() {
      this.$modal.show('add-participant-modal');
    },
    async loadParticipantsList() {
      this.setLoading(true);
      const { data } = await getLoyaltyProgramParticipants(this.userId);
      this.participants = data || [];
      this.setLoading(false);
    }
  },
  metaInfo: {
    title: 'Loyalty Program',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/css/common/icons';
@import '../../../assets/scss/common/buttons';
@import '../../../assets/scss/helpers/colors';

.loyalty-program {
  &__container {
    margin-bottom: 40px;
  }

  &__breadcrumbs {
    margin: 30px 0 22px;

    font-size: 15px;
    font-weight: bold;
    color: $grey-color;
  }

  &__info {
    width: 980px;
    min-height: 200px;

    display: flex;
    justify-content: space-around;
    align-items: center;

    margin: 0 auto 40px;
    padding: 20px 40px;

    background-color: $white;
    border-radius: 10px;
  }

  &__add-participent-container {
    width: 100%;

    display: flex;
    justify-content: center;
  }

  &__add-participant-button {
    height: 40px;
    padding: 10px 15px;

    &:hover {
      .loyalty-program__plus-icon {
        background-image: url(~@/assets/images/dashboard/icons/plus-icon-white.svg);
      }
    }
  }

  &__mobile-footer {
    display: none;
  }
}

.breadcrumbs {
  &__current {
    font-size: 18px;
    color: $main-title-color;
  }

  &__info-icon {
    display: none;
  }
}

.info {
  &__text {
    width: 600px;

    display: flex;
    flex-direction: column;

    font-size: 13px;
    line-height: 18px;
    color: $text-color;
  }
}

@media (max-width: 1000px) {
  .loyalty-program {
    &__info {
      width: 760px;
      min-height: 210px;
    }
  }
}

@media (max-width: 767px) {
  .loyalty-program {
    &__container {
      margin-bottom: 60px;
      padding: 0 15px;
    }

    &__breadcrumbs {
      margin: 16px 0 23px;
      font-size: 12px;
    }

    &__info {
      display: none;
    }

    &__add-participent-container {
      display: none;
    }

    &__add-participant-button {
      height: 30px;
      padding: 5px 15px;
    }

    &__mobile-footer {
      height: 60px;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: $white;
      box-shadow: 0px -6px 7px rgba(148, 166, 176, 0.15);
    }
  }

  .breadcrumbs {
    &__current {
      font-size: 14px;
    }

    &__info-icon {
      display: inline-block;
      margin-bottom: -2px;
    }
  }
}
</style>
