<template>
  <main class="card" :class="{ 'card--colored': isFullPercentage }">
    <section class="card__info-section">
      <div class="card__info-container flex-column--align-center">
        <div
          class="card__icon custom-icon cursor-default otherXS"
          :class="`icon-${redeemCard.icon}`"
        ></div>
        <div class="card__title">{{ redeemCard.title }}</div>
        <div class="card__description">
          {{ redeemCard.description }}
        </div>
      </div>
      <div class="card__points-container">
        <div class="card__points-amount">
          {{ getPointsCountTranslation(redeemCard.pointsAmount) }}
        </div>
        <div class="card__points-chart">
          <percentage-circle-chart :percentage="redeemCard.progressPercentage" />
        </div>
      </div>
    </section>
    <section class="card__footer" :class="{ 'footer--colored': isFullPercentage }">
      <div v-if="isFullPercentage" class="card__redeem-container flex--center--center">
        <button class="rounded-button-orange" @click="onRedeem">
          {{ $t('dashboard.label.redeem') }}
        </button>
      </div>
      <div v-else class="card__equivalent-container">
        <span>
          {{ $t('dashboard.label.sellEquivalent') }}
        </span>
        <span class="footer__ingredient-text">
          {{ redeemCard.serumPCount }}× Universkin Serum P ({{
            $t('dashboard.label.redeemThreeIngredients')
          }})
        </span>
        <span>{{ $t('dashboard.label.or') }}</span>
        <span class="footer__ingredient-text"
          >{{ redeemCard.nexUltraBCount }}× Nexultra B (30 {{ $t('dashboard.label.ml') }})</span
        >
        <span>{{ $t('dashboard.label.or') }}</span>
        <span class="footer__ingredient-text">{{ redeemCard.nexUltraOCount }}× L'Huile - Universal Cleansing Oil</span>
        <span>{{ $t('dashboard.label.toAchieveGoal') }}</span>
      </div>
    </section>
  </main>
</template>

<script>
import PercentageCircleChart from '@/modules/dashboard/components/common/PercentageCircleChart';
import RedeemCardConfig from '@/modules/dashboard/components/loyalty-program/RedeemCardConfig';

import { getPointsCountTranslation } from '@/modules/dashboard/utils';

import { REDEEM_CARD_TYPES } from '@/modules/dashboard/api/constants';

const REDEEM_CARD_ICONS = {
  [REDEEM_CARD_TYPES.AMAZON_50]: 'amazon',
  [REDEEM_CARD_TYPES.FREE_PRODUCTS]: 'free-products'
};

const REDEEM_CARD_TITLES = {
  [REDEEM_CARD_TYPES.AMAZON_50]: ['dashboard.label.amazonGiftCard', ['€50']],
  [REDEEM_CARD_TYPES.FREE_PRODUCTS]: ['dashboard.label.freeProducts']
};

const REDEEM_CARD_DESCRIPTIONS = {
  [REDEEM_CARD_TYPES.AMAZON_50]: 'dashboard.label.giftCard',
  [REDEEM_CARD_TYPES.FREE_PRODUCTS]: 'dashboard.label.redeemFreeProducts'
};

const REDEEM_CARD_POINTS_AMOUNT_FIELDS = {
  [REDEEM_CARD_TYPES.AMAZON_50]: 'giftCardPoints',
  [REDEEM_CARD_TYPES.FREE_PRODUCTS]: 'freeProductsPoints'
};

const REDEEM_CARD_POINTS_PERCENTAGE_FIELDS = {
  [REDEEM_CARD_TYPES.AMAZON_50]: 'amazonGiftCardPercentage',
  [REDEEM_CARD_TYPES.FREE_PRODUCTS]: 'freeProductsPercentage'
};

const REDEEM_CARD_SERUM_P_COUNT_FIELDS = {
  [REDEEM_CARD_TYPES.AMAZON_50]: 'tillGiftCardSerumP',
  [REDEEM_CARD_TYPES.FREE_PRODUCTS]: 'tillProductsSerumP'
};

const REDEEM_CARD_NEXULTRA_B_COUNT_FIELDS = {
  [REDEEM_CARD_TYPES.AMAZON_50]: 'tillGiftCardNexUltraB',
  [REDEEM_CARD_TYPES.FREE_PRODUCTS]: 'tillProductsNexUltraB'
};

const REDEEM_CARD_NEXULTRA_O_COUNT_FIELDS = {
  [REDEEM_CARD_TYPES.AMAZON_50]: 'tillGiftCardNexUltraO',
  [REDEEM_CARD_TYPES.FREE_PRODUCTS]: 'tillProductsNexUltraO'
};

export default {
  name: 'RedeemCard',
  components: { PercentageCircleChart },
  props: {
    cardType: {
      type: String,
      required: true
    },
    participantStatistics: {
      type: Object,
      required: true
    }
  },
  computed: {
    redeemCard() {
      const [giftCardTitleI18NKey, giftCardDefaultPrice] = REDEEM_CARD_TITLES[this.cardType];

      return new RedeemCardConfig({
        icon: REDEEM_CARD_ICONS[this.cardType],
        title: this.$t(giftCardTitleI18NKey, [
          this.participantStatistics.giftCardPrice || giftCardDefaultPrice
        ]),
        description: this.$t(REDEEM_CARD_DESCRIPTIONS[this.cardType]),
        pointsAmount: this.participantStatistics[REDEEM_CARD_POINTS_AMOUNT_FIELDS[this.cardType]],
        progressPercentage: this.participantStatistics[
          REDEEM_CARD_POINTS_PERCENTAGE_FIELDS[this.cardType]
        ],
        serumPCount: this.participantStatistics[REDEEM_CARD_SERUM_P_COUNT_FIELDS[this.cardType]],
        nexUltraBCount: this.participantStatistics[
          REDEEM_CARD_NEXULTRA_B_COUNT_FIELDS[this.cardType]
        ],
        nexUltraOCount: this.participantStatistics[
          REDEEM_CARD_NEXULTRA_O_COUNT_FIELDS[this.cardType]
        ]
      });
    },
    isFullPercentage() {
      return this.redeemCard.progressPercentage === 100;
    }
  },
  methods: {
    onRedeem() {
      this.$emit('redeem', this.cardType);
    },
    getPointsCountTranslation(numberOfPoints) {
      return getPointsCountTranslation(this.$t, numberOfPoints);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.card {
  width: 230px;
  height: max-content;

  border: 1px solid $border-light-grey-color;
  border-radius: 8px;

  &--colored {
    border: 1px solid $karry;
    border-radius: 8px;
  }

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &__info-section {
    height: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 25px 10px 20px;
    background-color: $white;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__info-container {
    max-width: 100%;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 19px;
    color: $main-title-color;
    font-weight: 600;
    text-align: center;
  }

  &__description {
    height: 117px;
    max-width: 100%;
    font-size: 11px;
    line-height: 13px;
    color: $text-color;
    text-align: center;
  }

  &__points-container {
    margin-top: auto;
  }

  &__points-amount {
    font-size: 13px;
    line-height: 15px;
    color: $text-color;
    font-weight: 600;
  }

  &__points-chart {
    width: 57px;
    height: 57px;
    margin: 0 auto;
  }

  &__icon,
  &__description,
  &__points-amount {
    margin-bottom: 15px;
  }

  &__footer {
    height: 150px;
    background-color: $white-smoke-color;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &__redeem-container {
    height: 100%;
  }

  &__equivalent-container {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 10px 15px;
    font-size: 11px;
    line-height: 13px;
    color: $grey-color;
  }
}

.footer {
  &__ingredient-text {
    color: $text-color;
    text-align: center;
  }

  &--colored {
    background-color: $linen-color;
  }
}

@media (max-width: 767px) {
  .card {
    height: auto;
    width: 100%;

    &:not(:last-of-type) {
      margin: 0 0 10px;
    }

    &__info-section {
      height: auto;

      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    &__info-container {
      align-items: flex-start;
      margin-right: 15px;
    }

    &__title {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 14px;
      text-align: left;
    }

    &__description {
      height: auto;
      margin-bottom: 0;
      font-size: 10px;
      line-height: 12px;
      text-align: start;
    }

    &__points-container {
      min-width: 60px;
      flex-shrink: 0;
      margin-top: 0;
    }

    &__points-amount {
      margin-bottom: 20px;
      font-size: 12px;
      line-height: 14px;
    }

    &__footer {
      height: auto;
    }

    &__redeem-container {
      padding: 15px 0;
    }

    &__equivalent-container {
      height: 150px;
    }
  }
}
</style>
