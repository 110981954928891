<template>
  <main class="redeem">
    <mobile-header>
      <span slot="header-title">{{ $t('dashboard.button.redeem') }}</span>
    </mobile-header>
    <div class="redeem__container">
      <section class="redeem__participant flex--space-between--center mb20">
        <div class="participant__name-container flex--align-center">
          <div
            class="participant__status-icon"
            :class="`participant__status-icon--${statusIconClassPostfix}`"
          ></div>
          <div class="participant__name ellipsized">{{ participantData.name }}</div>
        </div>
        <div class="participant__points-container">
          <div class="participant__total-points">
            {{ getTotalPointsCountTranslation(participantData.totalPoints) }}
          </div>
          <div class="participant__current-points">
            {{ getPointsCountTranslation(participantData.currentPoints) }}
          </div>
        </div>
      </section>
      <section class="redeem__card-container">
        <redeem-card
          v-for="redeemCardType in redeemCards"
          :key="redeemCardType"
          :card-type="redeemCardType"
          :participant-statistics="participantStatistics"
          @redeem="onRedeem"
        />
      </section>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import RedeemCard from '@/modules/dashboard/components/loyalty-program/RedeemCard';

import RoutingInfo from '@/modules/dashboard/components/common/RoutingInfo';

import { getLoyaltyProgramParticipant, getParticipantStatistics } from '@/modules/dashboard/api';
import { handleRoutingInfo, getPointsCountTranslation } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import {
  REDEEM_CARD_TYPES,
  REDEEM_CARD_TYPE_TO_REDEEM_TYPE_BINDING
} from '@/modules/dashboard/api/constants';

const REDEEM_CARDS = [REDEEM_CARD_TYPES.AMAZON_50, REDEEM_CARD_TYPES.FREE_PRODUCTS];

export default {
  name: 'Redeem',
  components: { MobileHeader, RedeemCard },
  props: {
    participantId: {
      type: Number,
      required: true
    },
    participant: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      participantData: {},
      participantStatistics: {},
      redeemCards: REDEEM_CARDS
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    }),
    statusIconClassPostfix() {
      return (this.participantData.statusColor || 'grey').toLowerCase();
    }
  },
  async mounted() {
    this.setLoading(true);

    if (this.participant.id) {
      this.participantData = this.participant;
    }

    if (this.participantId) {
      const { data } = await getLoyaltyProgramParticipant(this.userId, this.participantId);
      this.participantData = data;
      this.participantStatistics = (
        await getParticipantStatistics(this.userId, this.participantId)
      ).data;
    }

    this.setLoading(false);
  },
  methods: {
    ...mapActions({ setLoading: rootTypes.actions.SET_LOADING }),
    onRedeem(cardType) {
      this.$modal.hide('loyalty-program-redeem-modal');

      const redeemType = REDEEM_CARD_TYPE_TO_REDEEM_TYPE_BINDING[cardType];

      handleRoutingInfo(
        this.getContactInformationRoutingInfo(redeemType),
        this.$router,
        this.$modal
      );
    },
    getContactInformationRoutingInfo(redeemType) {
      const params = { participantId: this.participantId, redeemType };

      return new RoutingInfo('contact-information-modal', 'RedeemContactInformation', params);
    },
    getPointsCountTranslation(numberOfPoints) {
      return getPointsCountTranslation(this.$t, numberOfPoints);
    },
    getTotalPointsCountTranslation(numberOfPoints) {
      return getPointsCountTranslation(this.$t, numberOfPoints, true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.redeem {
  &__container {
    height: 650px;
    padding: 20px 30px 30px;
  }

  &__participant {
    height: 80px;
    padding: 30px 25px;
    background-color: $background;
    border-radius: 8px;
  }

  &__card-container {
    height: 502px;
    display: flex;
    justify-content: center;
  }
}

.participant {
  &__status-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 15px;
    border-radius: 50%;

    &--grey {
      background-color: $grey-color;
    }

    &--bronze {
      background-color: $bright-orange-color;
    }

    &--gold {
      background-color: $festival-color;
    }
  }

  &__name-container {
    width: 230px;
  }

  &__name {
    font-size: 15px;
    font-weight: 600;
    line-height: 19px;

    color: $main-title-color;
  }

  &__points-container {
    width: 55%;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__total-points {
    font-size: 13px;
    line-height: 15px;

    color: $grey-color;
  }

  &__current-points {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;

    color: $text-color;
  }
}

.total-point {
  &__points {
    display: inline;
  }
}

@media (max-width: 767px) {
  .redeem {
    &__container {
      height: auto;
      padding: 65px 10px 20px;

      background-color: $white;
    }

    &__card-container {
      height: auto;
      flex-direction: column;
    }

    &__participant {
      height: 50px;
      padding: 15px 10px;
      background-color: $white;
    }
  }

  .participant {
    &__status-icon {
      height: 16px;
      width: 16px;
      margin-right: 10px;
    }

    &__name-container {
      width: 70%;
    }

    &__name {
      font-size: 14px;
      line-height: 17px;
    }

    &__points-container {
      width: auto;
      flex-direction: column;
    }

    &__total-points {
      order: 1;
      font-size: 10px;
      line-height: 12px;
      color: $grey-color;
    }

    &__current-points {
      order: 0;
      font-size: 12px;
      line-height: 14px;
      color: $text-color;
    }
  }

  .total-point {
    &__points {
      display: none;
    }
  }
}
</style>
