export default class RedeemCardConfig {
  constructor({
    icon,
    title,
    description,
    pointsAmount,
    progressPercentage,
    serumPCount,
    nexUltraBCount,
    nexUltraOCount
  }) {
    this.icon = icon;
    this.title = title;
    this.description = description;
    this.pointsAmount = pointsAmount;
    this.progressPercentage = progressPercentage;
    this.serumPCount = serumPCount;
    this.nexUltraBCount = nexUltraBCount;
    this.nexUltraOCount = nexUltraOCount;
  }
}
