<template>
  <modal
    name="loyalty-program-info-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="300"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <div class="custom-icon icon-loyalty-program cursor-default mb20"></div>
        <section class="loyalty-info-text mb30">
          <div class="bold mb15">{{ $t('dashboard.label.introductionWelcome') }}</div>
          <span class="pre-wrap">{{ $t('dashboard.label.introduction') }}</span>
        </section>
        <div class="cancel-button pointer" @click="closeModal">
          {{ $t('dashboard.action.close') }}
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'LoyaltyProgramInfoModal',
  methods: {
    closeModal() {
      this.$modal.hide('loyalty-program-info-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.modal-window-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
}

.loyalty-info-text {
  font-size: 13px;
  line-height: 15px;

  color: $grey-color;
}

.cancel-button {
  font-size: 13px;
}
</style>
